<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.maintenanceRemainderReport')">
      <form v-on:submit.prevent="getMaintenanceReport">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            {{ $t('message.machineIdFarm') }} :
            <br>
            <model-list-select
              :list="machineData"
              v-model="searchParams.machineId"
              option-value="machineId"
              option-text="name"
              :placeholder="$t('message.machineIdFarm')"
            ></model-list-select>
          </div>
          <div class="col-sm-6 col-md-2">
            {{ $t('message.deviceId') }} :
            <br>
            <b-form-input v-model="searchParams.deviceId"/>
          </div>
          <div class="col-sm-6 col-md-3">
            {{ $t('message.selectMonth') }} :
            <br>
            <vue-monthly-picker
              v-model="selectedMonth"
              :monthLabels="monthName"
              dateFormat="MM/YYYY"
            ></vue-monthly-picker>
          </div>
          <div class="col-sm-6 col-md-4">
            <br>
            <b-btn variant="primary" type="submit">
              <i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}
            </b-btn>&nbsp;&nbsp;&nbsp;
            <!-- <b-btn variant="danger" @click="newReport()">
              <i class="fa fa-plus-square"></i>&nbsp;{{ $t('message.create') }}
            </b-btn> -->
          </div>
        </div>
      </form>
      <div class="table-responsive">
        <table class="table-modal">
          <thead style="text-align: center">
            <tr>
              <th>{{ $t('message.machineIdFarm') }}</th>
              <th>{{ $t('message.deviceId') }}</th>
              <th>{{ $t('message.machine') }}</th>
              <th>{{ $t('message.maintenanceDate') }}</th>
              <th>{{ $t('message.details') }}</th>
              <th>{{ $t('message.authority') }}</th>
              <th>{{ $t('message.department') }}</th>
              <th>{{ $t('message.createBy') }}</th>
            </tr>
          </thead>
          <tbody style="text-align: center">
            <tr v-for="item in rowData">
              <td>{{item.machineId}}</td>
              <td>{{item.deviceId}}</td>
              <td>{{item.deviceDetails}}</td>
              <template
                v-if="new Date(item.date) - now <= 259200000 && new Date(item.date) - now > 0"
              >
                <td style="text-align: center">
                  <span
                    class="badge badge-warning"
                    style="font-size: 100%"
                  >{{item.date | moment("YYYY-MM-DD HH:mm")}}</span>
                </td>
              </template>
              <template v-else-if="new Date(item.date) - now < 0">
                <td style="text-align: center">
                  <span
                    class="badge badge-danger"
                    style="font-size: 100%"
                  >{{item.date | moment("YYYY-MM-DD HH:mm")}}</span>
                </td>
              </template>
              <template v-else-if="new Date(item.date) - now > 259200000">
                <td style="text-align: center">
                  <span
                    class="badge badge-success"
                    style="font-size: 100%"
                  >{{item.date | moment("YYYY-MM-DD HH:mm")}}</span>
                </td>
              </template>
              <td>{{item.details}}</td>
              <td>{{item.authority}}</td>
              <td>{{item.department}}</td>
              <td>{{item.createBy}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

    <b-modal
      ref="createModal"
      @ok="createReport()"
      @cancel="getMaintenanceReport()"
      :ok-title="$t('message.create')"
      :cancel-title="$t('message.cancel')"
      :title="$t('message.createReport')"
      size="lg"
    >
      <table class="table-modal">
        <tr>
          <td>{{ $t('message.machineIdFarm') }}</td>
          <td>
            <model-list-select
              :list="machineData"
              v-model="newReportData.machineId"
              option-value="machineId"
              option-text="name"
              :placeholder="$t('message.machineIdFarm')"
            ></model-list-select>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.deviceId') }}</td>
          <td>
            <b-form-input
              type="number"
              :placeholder="$t('message.deviceId')"
              v-model="newReportData.deviceId"
            ></b-form-input>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.details') }}</td>
          <td>
            <b-form-textarea type="text" v-model="newReportData.details"></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.maintenanceDate') }}</td>
          <td>
            <date-picker
              type="datetime"
              v-model="newReportData.date"
              lang="en"
              format="yyyy-MM-dd HH:mm:ss"
            ></date-picker>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.authority') }}</td>
          <td>
            <b-form-textarea type="text" v-model="newReportData.authority"></b-form-textarea>
          </td>
        </tr>
        <tr>
          <td>{{ $t('message.department') }}</td>
          <td>
            <b-form-textarea type="text" v-model="newReportData.department"></b-form-textarea>
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>


<script>
import moment from "moment";
import webServices from "../../script";
import VueMonthlyPicker from "vue-monthly-picker";
import DatePicker from "../../custom_modules/vue2-datepicker";
import {
  ModelListSelect,
  MultiListSelect
} from "../../custom_modules/search-select";
export default {
  name: "Reports",
  components: {
    MultiListSelect,
    ModelListSelect,
    DatePicker,
    VueMonthlyPicker
  },
  data() {
    return {
      rowData: [],
      modalData: {},
      now: new Date(),
      machineData: [],
      searchParams: {},
      newReportData: {},
      selectedMachine: [],
      status: [
        { value: "SUCCESS", text: "แก้ไขแล้ว" },
        { value: "PROCESSING", text: "กำลังดำเนินการ" },
        { value: "RECEIVED", text: "ได้รับแจ้งปัญหาแล้ว" }
      ],
      options: [
        { value: true, text: "ในประกัน" },
        { value: false, text: "นอกประกัน" }
      ],
      selectedMonth: moment(new Date()),
      monthName: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]
    };
  },
  methods: {
    getMachine() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then(res => {
          this.$Progress.finish();
          this.machineData = res.data;
          // console.log(res.data)
        })
        .catch(err => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    getMaintenanceReport() {
      this.$Progress.start();
      this.searchParams.month = new Date(this.selectedMonth)
      webServices
        .getMaintenanceRemainderReport(this.searchParams)
        .then(res => {
          if (res.data.success) {
            this.$Progress.finish();
            this.rowData = res.data.reportData;
          } else {
            this.$Progress.fail();
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
        })
        .catch(err => {
          this.$Progress.fail();
          console.log('error @get Report')
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด"
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },
    newReport() {
      this.$refs.createModal.show();
    },

    createReport() {
      this.newReportData.date = moment(this.newReportData.date).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      webServices
        .createMaintenanceRemainderReport(this.newReportData)
        .then(res => {
          if (res.data.success) {
            this.$Progress.finish();
            this.$toast.success({
              title: "SUCCESS",
              message: "สำเร็จ"
            });
            this.modalData = {};
          } else {
            this.$Progress.fail();
            const error = webServices.showError(res.data);
            this.$toast.error({
              title: "ERROR",
              message: JSON.stringify(error)
            });
          }
          this.newReportData = {};
          this.getMaintenanceReport();
        })
        .catch(err => {
          this.$Progress.fail();
          console.log('error @create Report')
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: "เกิดข้อผิดพลาด"
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.getMaintenanceReport();
        });
    }
  },
  mounted() {
    this.getMachine();
    this.getMaintenanceReport();
  }
};
</script>